// Needs refactoring
// stylelint-disable selector-max-type, selector-no-qualifying-type

.suggester {
  position: relative;
  top: 0;
  left: 0;
  min-width: 180px;
  padding: 0;
  margin: 0;
  margin-top: $spacer-4;
  list-style: none;
  cursor: pointer;
  background: var(--color-canvas-overlay);
  border: $border-width $border-style var(--color-border-default);
  border-radius: $border-radius;
  box-shadow: var(--color-shadow-medium);

  li {
    display: block;
    padding: $spacer-1 $spacer-2;
    font-weight: $font-weight-semibold;
    border-bottom: $border-width $border-style var(--color-border-muted);

    small {
      font-weight: $font-weight-normal;
      color: var(--color-fg-muted);
    }

    &:last-child {
      border-bottom: 0;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &:hover {
      color: var(--color-fg-on-emphasis);
      text-decoration: none;
      background: var(--color-accent-emphasis);

      small {
        color: var(--color-fg-on-emphasis);
      }

      .octicon {
        color: inherit !important;
      }
    }

    &[aria-selected='true'],
    &.navigation-focus {
      color: var(--color-fg-on-emphasis);
      text-decoration: none;
      background: var(--color-accent-emphasis);

      small {
        color: var(--color-fg-on-emphasis);
      }

      .octicon {
        color: inherit !important;
      }
    }
  }
}

.suggester-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
}

// Responsive

.page-responsive {
  @media (max-width: $width-sm) {
    .suggester-container {
      right: $spacer-2 !important;
      left: $spacer-2 !important;
    }

    .suggester li {
      padding: $spacer-2 $spacer-3;
    }
  }
}
