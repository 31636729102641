// Stacked label (default)
.autocomplete-label-stacked {
  display: block;
  margin-bottom: $spacer-2 * 0.75;
}

// Inline label (non-default)
.autocomplete-label-inline {
  display: inline;
  margin-right: $spacer-2 * 0.75;
}

// Switch to stacked at smaller viewport
@media (max-width: $width-sm) {
  .autocomplete-label-inline {
    display: block;
    margin-bottom: $spacer-2 * 0.75;
  }
}

// Wrapper for the input and result elements to ensure alignment
.autocomplete-body {
  position: relative;
  display: inline;
}

// Wrapper and conditional styles for when an icon is added
.autocomplete-embedded-icon-wrap {
  display: inline-flex;
  padding: $spacer-1 $spacer-2;
  align-items: center;

  &:focus-within {
    border-color: var(--color-accent-fg);

    @include focusBoxShadowInset;
  }

  .form-control {
    padding: 0;
    margin-left: $spacer-2;
    // stylelint-disable-next-line
    border: none;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: none;
    }
  }
}

// A pop up list of items used to show autocompleted results
.autocomplete-results {
  position: absolute;
  left: 0;
  z-index: 99;
  width: max-content;
  min-width: 100%;
  max-height: 20em;
  overflow-y: auto;
  // stylelint-disable-next-line primer/typography
  font-size: 13px;
  list-style: none;
  background: var(--color-canvas-overlay);
  border: $border-width $border-style var(--color-border-default);
  border-radius: $border-radius;
  box-shadow: var(--color-shadow-medium);
}

// One of the items that appears within an autocomplete group
// Bold black text on white background

.autocomplete-item {
  display: block;
  width: 100%;
  padding: $spacer-1 $spacer-2;
  overflow: hidden;
  font-weight: $font-weight-bold;
  color: var(--color-fg-default);
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-color: var(--color-canvas-overlay);
  border: 0;

  &:hover {
    color: var(--color-fg-on-emphasis);
    text-decoration: none;
    background-color: var(--color-accent-emphasis);

    // Inherit color on all child elements to ensure enough contrast
    * {
      color: inherit !important;
    }
  }

  &.selected,
  &[aria-selected='true'],
  &.navigation-focus {
    color: var(--color-fg-on-emphasis);
    text-decoration: none;
    background-color: var(--color-accent-emphasis);

    // Inherit color on all child elements to ensure enough contrast
    * {
      color: inherit !important;
    }
  }
}
