// stylelint-disable selector-max-type, selector-no-qualifying-type

// A nice way to display branch names inside the UI. Can be a link or not.

.branch-name {
  display: inline-block;
  // stylelint-disable-next-line primer/spacing
  padding: 2px 6px;
  font: 12px $mono-font;
  color: var(--color-fg-muted);
  word-break: break-all;
  background-color: var(--color-accent-subtle);
  border-radius: $border-radius;

  .octicon {
    // stylelint-disable-next-line primer/spacing
    margin: 1px -2px 0 0;
    color: var(--color-fg-muted);
  }
}

// When a branch name is a link

a.branch-name {
  color: var(--color-accent-fg);
  background-color: var(--color-accent-subtle);

  .octicon {
    color: var(--color-accent-fg);
  }
}
