// Generate a two-color caret for any element.
@mixin double-caret($background: var(--color-canvas-default), $border: var(--color-border-default)) {
  &::after,
  &::before {
    position: absolute;
    top: 11px;
    right: 100%;
    left: -8px;
    display: block;
    width: 8px;
    height: 16px;
    pointer-events: none;
    content: ' ';
    clip-path: polygon(0 50%, 100% 0, 100% 100%);
  }

  &::after {
    // stylelint-disable-next-line primer/spacing
    margin-left: 2px;
    background-color: var(--color-canvas-default);
    background-image: linear-gradient($background, $background);
  }

  &::before {
    background-color: $border;
  }
}

// global focus styles

// inset box-shadow for form controls
@mixin focusBoxShadowInset($outlineWidth: 1px, $outlineColor: var(--color-accent-fg)) {
  border-color: var(--color-accent-fg);
  outline: none;
  box-shadow: inset 0 0 0 $outlineWidth $outlineColor;
}

// box-shadow for :target styles (no inset)
// !important to override PCSS utilities
@mixin targetBoxShadow($outlineWidth: 2px, $outlineColor: var(--color-accent-fg)) {
  outline: none !important;
  box-shadow: 0 0 0 $outlineWidth $outlineColor !important;
}

// outline
@mixin focusOutline($outlineOffset: -2px, $outlineColor: var(--color-accent-fg)) {
  outline: 2px solid $outlineColor;
  outline-offset: $outlineOffset;
  box-shadow: none;
}

// outline with fg box-shadow for buttons
@mixin focusOutlineOnEmphasis($outlineOffset: -2px, $outlineColor: var(--color-accent-fg)) {
  outline: 2px solid $outlineColor;
  outline-offset: $outlineOffset;
  box-shadow: inset 0 0 0 3px var(--color-fg-on-emphasis);
}

// if min-width is undefined, return only min-height
@mixin minTouchTarget($min-height: 32px, $min-width: '') {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  min-height: $min-height;
  content: '';
  transform: translateX(-50%) translateY(-50%);

  @if $min-width != '' {
    min-width: $min-width;
  }
}
