// Subhead styles
.Subhead {
  display: flex;
  padding-bottom: $spacer-2;
  margin-bottom: $spacer-3;
  border-bottom: $border-width $border-style var(--color-border-muted);
  flex-flow: row wrap;
  justify-content: flex-end; // Keep actions right aligned.
}

// Modifier class to give a lot of breathing room between sections of content.
.Subhead--spacious {
  margin-top: $spacer-6;
}

// <h2> sized heading with normal font weight
.Subhead-heading {
  font-size: $h2-size;
  font-weight: $font-weight-normal;
  flex: 1 1 auto;
  order: 0;
}

// Make the text bold and red for dangerous content
.Subhead-heading--danger {
  font-weight: $font-weight-bold;
  color: var(--color-danger-fg);
}

// One-liner of supporting text
.Subhead-description {
  font-size: $body-font-size;
  color: var(--color-fg-muted);
  flex: 1 100%;
  order: 2;
}

// Add 1 or 2 buttons to the right of the heading
.Subhead-actions {
  margin: $spacer-1 0 $spacer-1 $spacer-1;
  align-self: center;
  justify-content: flex-end;
  order: 1;

  + .Subhead-description {
    margin-top: $spacer-1;
  }
}
